<!-- eslint-disable no-fallthrough -->
<script>


import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import { authComputed, authMethods, authGetters } from '../../../state/helpers.js'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import { callApi } from '../../../msal/msalApi'
import Swal from 'sweetalert2'
import { useRoute } from 'vue-router'


export default {
    components: { Layout, PageHeader },
    data() {
        return {
            data: null,
            userEmail: null,
            totalResults: 0,
            franchiseSelections: null,
            franchiseLocations: null,
            currentPage: 1,
            itemsPerPage: 10,
            selectFranchise: '',
            selectLocation: '',
            inputSearch: '',
            errorMessage: '',
            practiceRoles: '',
            showLoading: false,
            loading: false,

            selectedFilter: '',
            unassignedOnly: false,
            activeDate: (function () {
                var date = new Date();
                date.setDate(date.getDate() - 90);
                return date.toISOString().substr(0, 10);
            })(), // Valor predeterminado: fecha actual,
            calendarDate: undefined,
            monitoringFilter: 0, // Valor predeterminado: 'all' (todos)
            selectedFilterText: 'Active during last 90 days', // Agrega esta nueva variable para almacenar el texto seleccionado
            activeFiltersText: '',

            coachesList: [],
            selectedCoaches: [],
            selectFranchises: '',
            selectSelections: '',
            showActiveInfo: true,
        };

    },
    methods: {
        async call_API() {
            try {
                this.userEmail = this.getEmail || '';
                this.showLoading = true;
                this.loading = true;
                let url = `${process.env.VUE_APP_WLT_API}Client/GetPaged?memberEmail=${this.userEmail}&page=${this.currentPage}&pageSize=${this.itemsPerPage}`;

                if (this.activeDate) {
                    url += `&activeDate=${this.activeDate}`;
                }

                if (this.unassignedOnly) {
                    url += '&unassingedOnly=' + this.unassignedOnly;
                }

                if (this.monitoringFilter) {
                    url += `&monitoringFilter=${this.monitoringFilter}`;
                }

                if (this.inputSearch) {
                    url += `&searchString=${this.inputSearch}`;
                }

                if (this.selectedCoaches.length > 0) {
                    const coachesParam = this.selectedCoaches.map(coachId => `coaches=${coachId}`).join("&");
                    url += `&${coachesParam}`;
                }


                if (this.selectLocation && this.selectFranchise) {
                    url += `&locationsFilter[0]=${this.selectLocation}&franchiseFilter[1]=${this.selectFranchise}`;
                }
                if (this.selectFranchise) {
                    url += `&franchiseFilter[0]=${this.selectFranchise}`;
                }
                if (this.selectLocation) {
                    url += `&locationsFilter[0]=${this.selectLocation}`;
                }


                const response2 = await callApi(url, this.getToken);


                console.log(response2);
                const { items, totalItems, practiceRoles } = response2;
                this.data = items;
                this.totalResults = totalItems;
                this.franchiseLocations = response2.franchiseLocations;
                this.franchiseSelections = response2.franchiseSelections;
                this.practiceRoles = practiceRoles;
                this.coachesList = response2.coachFilter

                //this.updateActiveFilters()
                console.log(this.unassignedOnly)

                console.log(url)
            } catch (error) {
                error.message = 'Error obtaining clients list';
                Swal.fire(`<p class="h5">  ${error.message}</p>`, '', 'error');
            } finally {
                this.loading = false;
                this.showLoading = false;
            }
        },
        handleCurrentPageChange() {
            setTimeout(() => {
                this.call_API();
            }, 1000);
        },
        handleSelectFranchiseChange(franchise) {
            this.selectFranchise = franchise.target.value
            this.currentPage = 1;
            this.call_API();
        },
        handleSelectLocationChange(location) {
            this.selectLocation = location.target.value
            this.currentPage = 1;
            this.call_API();
        },
        handleMemberOnlyChange() {
            this.call_API();
        },
        handleStatusChange() {
            this.currentPage = 1;
            this.call_API();
        },
        handleInputSearchChange() {
            this.call_API();
        },
        changePage(page) {
            this.currentPage = page;
            setTimeout(() => {
                this.call_API();
            }, 1000);
        },
        goToFirstPage() {
            this.changePage(1);
        },
        goToLastPage() {
            this.changePage(Math.ceil(this.totalResults / this.itemsPerPage));
        },
        async updateData() {
            this.call_API()
        },
        resetFilters() {
            console.log(this.selectFranchise, this.selectFranchises)
            this.selectFranchises = ''
            this.selectSelections = ''
            this.inputSearch = '';
            this.selectLocation = '';
            this.selectFranchise = '';
            this.unassingedOnly = false
            this.activeDate = (function () {
                var date = new Date();
                date.setDate(date.getDate() - 90);
                return date.toISOString().substr(0, 10);
            })(), // Valor predeterminado: fecha actual,
                this.calendarDate = undefined,
                this.monitoringFilter = 0, // Valor predeterminado 'all' (todos)
                this.selectedFilterText = 'Active during last 90 days' // Agrega esta nueva variable para almacenar el texto seleccionado

            this.selectedCoaches = []
            this.coachesList = []
            // Limpiar la URL sin mantener los parámetros
            this.$router.replace({ path: '/client' });
            this.call_API();
        },
        nextGroup() {
            if (this.currentPageGroup < this.pageGroups.length) {
                this.currentPage = this.pageGroups[this.currentPageGroup][0];
                this.currentPageGroup++;
            }
        },
        getClientStateDisplay(clientState) {
            let state = '';
            if (clientState === 0) {
                state = 'Not Enrolled';
            } else if (clientState === 1) {
                state = 'Active';
            } else if (clientState === 2) {
                state = 'Monitored';
            } else if (clientState === 3) {
                state = 'Deactivated';
            } else if (clientState === 4) {
                state = 'Inactive';
            } else if (clientState === 5) {
                state = 'Unassigned';
            } else {
                state = 'Unknown';
            }
            return state;
        },

        handleActiveFilter(days) {
            this.calendarDate = null;
            if (days === 'today') {
                const today = new Date();
                this.activeDate = this.formatDateTime(today);
            } else {
                this.activeDate = days === 'ALL' ? null : this.calculateActiveDate(days);
            }
            this.selectedFilter = days;

            // Actualiza la variable selectedFilterText según el elemento seleccionado
            if (days === 'today') {
                this.selectedFilterText = 'Active today';
            } else if (days === '90') {
                this.selectedFilterText = 'Active during last 90 days';
            } else if (days === '180') {
                this.selectedFilterText = 'Active during last 180 days';
            } else if (days === '365') {
                this.selectedFilterText = 'Active during last 365 days';
            } else if (days === 'ALL') {
                this.selectedFilterText = 'All Active/Inactive';
            } else {
                this.selectedFilterText = 'Select';
            }
            this.showActiveInfo = true;

            this.updateActiveFilters()
            this.call_API();
        },

        updateActiveFilters() {
            const activeFilters = [];

            if (this.selectedFilter === 'today') {
                activeFilters.push('Active today');
            } else if (this.calendarDate) {
                activeFilters.push(this.selectedFilterText);

            }

            else if (this.selectedFilter !== 'ALL') {
                activeFilters.push(`Active During Last ${this.selectedFilter} days`);
            }

            if (this.unassignedOnly === "true" || this.unassignedOnly == true) {
                activeFilters.push('Un-assigned');
                this.showActiveInfo = false; // Oculta la información de "Active" cuando se selecciona "Un-assigned"
                activeFilters.shift()
            }

            if (this.monitoringFilter == 0) {
                //activeFilters.push('Monitored: All');
            } else if (this.monitoringFilter == 1) {
                activeFilters.push('Monitored Only');
            } else if (this.monitoringFilter == 2) {
                activeFilters.push('Inactive and Deactivated');
            }

            console.log(activeFilters)            // Eliminar duplicados y actualizar el arreglo de filtros activos
            this.activeFilters = Array.from(new Set(activeFilters));

            // Actualizar el párrafo que muestra los filtros activos
            this.updateActiveFiltersParagraph();
        },
        updateActiveFiltersParagraph() {
            if (this.activeFilters.length === 0) {
                this.activeFiltersText = 'Showing: All';
            } else {
                this.activeFiltersText = `Showing: ${this.activeFilters.join(', ')}`;
            }
        },

        handleActiveFilterByDate(date) {
            if (this.calendarDate) {
                this.selectedFilter = null;
                this.calendarDate = date ? this.formatDateTime(date) : null;
                this.activeDate = this.calendarDate;

                // Convertir la fecha en el formato 'MM/DD/YYYY'
                const parsedDate = new Date(date);
                const day = parsedDate.getDate().toString().padStart(2, '0');
                const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
                const year = parsedDate.getFullYear().toString();
                const formattedDate = `${month}/${day}/${year}`;

                this.selectedFilterText = `Active Since ${formattedDate}`;
                this.updateActiveFilters();

                this.call_API();
            }
        },

        handleUnassignedFilter() {
            this.unassigned = !this.unassigned;
            this.updateActiveFilters();

            this.call_API();
        },
        handleMonitoringFilter(option) {
            this.monitoringFilter = option.target.value;
            console.log(this.monitoringFilter);
            this.updateActiveFilters();

            this.call_API();
        },
        calculateActiveDate(days) {
            const activeDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
            const formattedDateTime = activeDate.toISOString().slice(0, 19).replace('T', ' ');
            console.log(formattedDateTime); // Opcional: Imprime la fecha y hora formateada
            return formattedDateTime;
        },
        formatDateTime(date) {
            const now = new Date(date);
            const timezoneOffset = now.getTimezoneOffset() * 60000; // Obtener la diferencia de zona horaria en milisegundos
            const localTime = now.getTime() - timezoneOffset; // Obtener la hora local en milisegundos
            const localDateTime = new Date(localTime).toISOString().slice(0, 19).replace('T', ' '); // Formatear la hora local
            console.log(localDateTime);
            return localDateTime;
        },

        async handleCoachesFilter() {
            this.call_API();
        },
    },

    mounted() {
        const { query } = useRoute();
        const {
            inputSearch,
            selectFranchise,
            selectLocation,
            selectedFilter,
            unassignedOnly,
            activeDate,
            calendarDate,
            monitoringFilter
        } = query;

        this.inputSearch = inputSearch;
        this.selectFranchise = selectFranchise;
        this.selectLocation = selectLocation;
        this.selectFranchises = selectFranchise ? selectFranchise : ''
        this.selectSelections = selectLocation ? selectLocation : ''
        this.selectedFilter = selectedFilter || '90';
        this.unassignedOnly = unassignedOnly || false;
        this.activeDate = activeDate || '';
        this.calendarDate = calendarDate || '';
        this.monitoringFilter = parseInt(monitoringFilter, 10) || 0;

        setTimeout(() => {
            this.handleActiveFilter(this.selectedFilter)
            //this.updateActiveFilters()
            //this.call_API()

        }, 500);
    },


    computed: {

        pageCount: function () {
            return Math.ceil(this.totalResults / this.itemsPerPage);
        },

        pagesToShow() {
            const pageCount = Math.ceil(this.totalResults / this.itemsPerPage);
            const pagesAroundCurrent = 2; // cambiar según sea necesario
            let startPage = Math.max(1, this.currentPage - pagesAroundCurrent);
            let endPage = Math.min(pageCount, this.currentPage + pagesAroundCurrent);
            const pages = [];

            // Agregar páginas al arreglo "pages"
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        },
        ...authComputed,
        ...authMethods,
        ...authGetters
    },

}
</script>
    
<template>
    <Layout>
        <PageHeader :title="Members" :items="items" />
        <div class="container-fluid bg-white pb-3">
            <form action="" class="pt-3">
                <div class="d-md-flex d-lg-flex">
                    <input type="text" class="col mx-2 form-control" @input="handleInputSearchChange" name="firstname"
                        placeholder="FIRST NAME,LAST NAME,EMAIL" v-model="inputSearch">

                    <select name="franchiseSelect" id="" class="col mx-2 form-control" @change="handleSelectFranchiseChange"
                        v-model="selectFranchises">
                        <option value="" selected>All Practice Groups</option>
                        <option :value="franchise.franchise_ID" v-for="(franchise, index) in franchiseSelections"
                            :key="index">
                            {{ franchise.franchiseName }}
                        </option>
                    </select>

                    <!--<select name="" id="" class="col mx-2 form-control" placeholder="ROL">
        <option :value="rol" v-for="(rol, index) in practiceRoles" :key="index">
          {{ rol }}
        </option>
      </select>-->

                    <div class="d-flex justify-content-between mt-3 mt-sm-0">
                        <button class="btn btn-primary" @click.prevent="updateData">Search</button>
                        <button class="btn border-dark mx-2" @click.prevent="resetFilters">Reset</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="container-fluid bg-white">
            <div class="d-flex justify-content-between">
                <h4 class="fw-bolder">LIST OF CLIENTS</h4>
                <div>
                    <!--<router-link class="btn btn-dark mx-3" to="/assing-coach"> Assing Coach</router-link>

                    <router-link class="btn btn-primary" to="/new-user"> +Add Client</router-link>-->
                </div>
            </div>

            <div>
                <div class="spinner-overlay text-center mt-5" v-if="loading">
                    <div class="spinner-border text-primary  custom-spinner" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div>Loading...</div>
                </div>
                <div v-if="!showLoading && data" class="table-responsive " style="height: 52rem;">
                    <table class="table mt-4 bg-white" v-bind:class="{ 'table-overlay': loading }">
                        <thead>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th class="btn-group">
                                <button class="btn btn-white dropdown-toggle fw-bold" type="button"
                                    data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
                                    {{ activeFiltersText }}
                                    <img src="https://cdn-icons-png.flaticon.com/512/10289/10289671.png" alt="" srcset=""
                                        width="13">
                                </button>
                                <ul class="dropdown-menu bg-white text-dark border border-dark"
                                    style="width: 17rem !important;">
                                    <p class="text-dark">Client Filters</p>
                                    <div class="btn-group border-top border-dark w-100">
                                        <button class="btn dropdown-toggle fw-bold mt-2" type="button"
                                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                            {{ selectedFilterText }}
                                            <img src="https://cdn-icons-png.flaticon.com/512/10289/10289671.png" alt=""
                                                srcset="" width="13">
                                        </button>
                                        <ul class="dropdown-menu border border-dark">
                                            <button class="btn text-start text-center w-100"
                                                :class="{ 'bg-warning w-100 fw-bold text-dark': selectedFilter === 'today' }"
                                                @click="handleActiveFilter('today')">Active today</button>
                                            <button class="btn text-start text-center w-100"
                                                :class="{ 'bg-warning w-100 fw-bold text-dark': selectedFilter === '90' }"
                                                @click="handleActiveFilter('90')">Active during last 90 days</button>
                                            <button class="btn text-start text-center w-100"
                                                :class="{ 'bg-warning w-100 fw-bold text-dark': selectedFilter === '180' }"
                                                @click="handleActiveFilter('180')">Active during last 180 days</button>
                                            <button class="btn text-start text-center w-100"
                                                :class="{ 'bg-warning w-100 fw-bold text-dark': selectedFilter === '365' }"
                                                @click="handleActiveFilter('365')">Active during last 365 days</button>
                                            <p class="text-center mt-2"><i class='bx bxs-calendar'></i> Any active since</p>

                                            <input class="form-control" type="datetime-local" name="date" id="date"
                                                v-model="calendarDate"
                                                @change="handleActiveFilterByDate($event.target.value)">
                                            <button class="btn text-start text-center w-100"
                                                :class="{ 'bg-warning w-100 fw-bold text-dark': selectedFilter === 'ALL' }"
                                                @click="handleActiveFilter('ALL')">All Active/Inactive</button>
                                        </ul>
                                    </div>
                                    <!--<p class="border-bottom border-dark mt-1">Assignment</p>-->
                                    <div class="check-container mt-2 mx-4">
                                        <div class="form-check mb-1 mt-2">
                                            <input class="form-check-input" type="checkbox" :value="unassignedOnly"
                                                id="unassigned" v-model="unassignedOnly" @change="handleUnassignedFilter">

                                            <label class="form-check-label fw-bold" for="unassigned">Un-assigned
                                                Only</label>
                                        </div>

                                    </div>
                                    <!-- <p class="border-bottom border-dark mt-3">Monitoring</p>-->
                                    <div class="btn-group mt-3">
                                        <select class="form-select fw-bold" v-model="monitoringFilter"
                                            @change="handleMonitoringFilter">
                                            <option value="1" selected>Monitored Only</option>
                                            <option value="2">Inactive and deactivated</option>
                                            <option value="0">All Monitored/Un-Monitored</option>
                                        </select>
                                    </div>
                                </ul>
                            </th>



                            <th>
                                <select name="selectLocation" id="" class="fw-bold form-select"
                                    @change="handleSelectLocationChange" v-model="selectSelections">
                                    <option value="">All Locations</option>
                                    <option :value="franchise.franchiseLocation_ID"
                                        v-for="(franchise, index) in franchiseLocations" :key="index">
                                        {{ franchise.locationName }} - {{ franchise.status }}
                                    </option>
                                </select>
                            </th>
                            <th>
                                <div class="dropdown">
                                    <button :disabled="coachesList.length === 0"
                                        class="btn btn-white fw-bold dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        COACH
                                        <img src="https://cdn-icons-png.flaticon.com/512/10289/10289671.png" alt=""
                                            srcset="" width="13">
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-white overflow-auto" style="height:250%;">
                                        <li v-for="(coaches, index) in coachesList" :key="index">
                                            <div class="form-check m-2">
                                                <input class="form-check-input" type="checkbox" :id="coaches.userAccount_ID"
                                                    :value="coaches.userAccount_ID" v-model="selectedCoaches"
                                                    @change="handleCoachesFilter">
                                                <label class="form-check-label fw-bold" :for="coaches.userAccount_ID">
                                                    {{ coaches.firstName }} {{ coaches.lastName }}
                                                </label>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </th>


                            <th>OPERATE</th>
                        </thead>
                        <tbody v-for="(client, index) in data" :key="index">
                            <tr>

                                <th class="w-25">
                                    {{ client.clientFirstName }}
                                </th>
                                <th class="w-25">
                                    {{ client.clientLastName }}
                                </th>
                                <th class="w-25">
                                    {{ getClientStateDisplay(client.clientState) }}
                                </th>
                                <th class=" w-25">
                                    {{ client.locationName }}
                                    <!-- <a href="#">SEE ALL</a>-->
                                </th>
                                <th class="w-25">
                                    {{ client.coachFirstName }} {{ client.coachLastName }}
                                </th>

                                <th class="w-25">
                                    <router-link class="mx-1" :to="{
                                        path: '/edit-client/' + client.clientEmail + '/' + client.franchise_ID,
                                        query: {
                                            inputSearch: inputSearch,
                                            selectLocation: selectLocation,
                                            selectFranchise: selectFranchise,
                                            unassignedOnly: unassignedOnly,
                                            selectedFilter: selectedFilter,
                                            activeDate: activeDate,
                                            calendarDate: calendarDate,
                                            monitoringFilter: monitoringFilter,
                                        }
                                    }">
                                        VIEW
                                    </router-link>
                                </th>
                            </tr>



                        </tbody>
                    </table>
                    <nav>
                        <nav>
                            <ul class="pagination pagination-sm justify-content-center">
                                <button class="btn mx-3"
                                    :class="{ 'btn-secondary': currentPage === 1, 'btn-info': currentPage !== 1 }"
                                    @click="goToFirstPage()"><i class='bx bx-first-page fw-bold'></i></button>

                                <button class="btn btn-info mx-3" :disabled="currentPage === 1"
                                    @click="changePage(currentPage - 1)">Previous</button>
                                <div v-for="(page, index) in pagesToShow" :key="index">
                                    <button class="btn mx-1"
                                        :class="{ 'btn-info': currentPage === page, 'btn-outline-info': currentPage !== page }"
                                        @click="changePage(page)">{{ page }}</button>
                                </div>
                                <button class="btn btn-primary " :disabled="currentPage * itemsPerPage >= totalResults"
                                    @click="changePage(currentPage + 1)">Next</button>

                                <button class="btn mx-5"
                                    :class="{ 'btn-info': currentPage === totalPages, 'btn-primary': currentPage !== totalPages }"
                                    @click="goToLastPage()"><i class='bx bx-last-page fw-bold'></i></button>

                            </ul>
                        </nav>
                    </nav>
                    <div class="result-count">
                        Displaying {{ (currentPage - 1) * itemsPerPage + 1 }} - {{ Math.min(currentPage * itemsPerPage,
                            totalResults) }} / {{ totalResults }}
                    </div>

                </div>
            </div>

        </div>

    </Layout>
</template>

<style>
.bg-soft {
    background-color: rgba(80, 165, 241, .25) !important;
}

.table-overlay {
    opacity: 0.1;
    pointer-events: none;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    animation: spin 1s linear infinite;
}

.spinner-border.custom-spinner {
    width: 6rem;
    height: 6rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-message {
    margin-top: 10px;
}

.spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.form-select {
    border: none;
}
</style>


